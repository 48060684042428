import * as Sentry from "@sentry/nextjs";
import { Options } from "@sentry/types";
import { User } from "firebase/auth";
import { ENVIRONMENT } from "../Common/consts/ENVIRONMENT";

export function setupSentry(options?: Options) {
  if (ENVIRONMENT.nodeEnv !== "production") return;
  if (!ENVIRONMENT.sentry.dsn) return;

  Sentry.init({
    dsn: ENVIRONMENT.sentry.dsn,
    environment: ENVIRONMENT.sentry.environment,
    tracesSampleRate: 1.0,
    debug: false,
    ...options,
  });
}

export function setupSentryUser(user?: User) {
  if (user) {
    Sentry.setUser({
      id: user.uid,
      name: user.displayName,
      email: user.email || undefined,
    });
  } else {
    Sentry.setUser(null);
  }
}


setupSentry();
import "../Setup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AppProps, AppType } from "next/app";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
require("../next-i18next.config");

const App: AppType = (props: AppProps) => {
  const { Component } = props;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no"
        />
        <meta key="description" name="description" content="Stai" />
        <title>Stai</title>
      </Head>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Component {...props.pageProps} />
      </LocalizationProvider>
    </>
  );
};

export default appWithTranslation(App);
